/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { Card } from './Card/Card';
import { LazyImage } from 'components/LazyImage';

const Container = styled.div`
  display: grid;
  gap: 24px;
  padding: 24px;
  height: fit-content;
`;
const Header = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 64px) max-content;
  justify-content: center;
  align-items: center;
  height: fit-content;
  gap: 12px;
  justify-content: center;
  border: 1px solid ${colorsV2.primary100};
  box-shadow: 1px 2px 0px 0px ${colorsV2.primary100};
  border-radius: 16px;
  padding: 16px 32px;
`;
const Content = styled.div`
  display: grid;
  gap: 24px;
`;

export const BlendedSmartLearningItem = ({
  title = '',
  image = '',
  imageAlt = '',
  list = []
}) => {
  return (
    <Container>
      <Header>
        <LazyImage src={image} alt={imageAlt} />
        <Typography variant="semi-bold/16-24" color={colorsV2.primary100}>
          {title}
        </Typography>
      </Header>
      <Content>
        {list?.map((eachItem, idx) => (
          <Card
            key={idx}
            image={eachItem?.image}
            imageAlt={eachItem?.imageAlt}
            title={eachItem?.title}
            description={eachItem?.description}
          />
        ))}
      </Content>
    </Container>
  );
};
