import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { TrackingCard } from './components/Card/TrackingCard';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled.div``;

const DolComprehensiveExperienceDetailBase = ({ data }) => {
  const { groups } = data || {};
  return (
    <Container>
      {groups?.map((eachGroup, idx) => (
        <TrackingCard
          key={idx}
          title={eachGroup?.title}
          list={eachGroup?.list}
          image={eachGroup?.image}
          imageAlt={eachGroup?.imageAlt}
          isTwoDot={eachGroup?.title === 'Ở NHÀ'}
        />
      ))}
    </Container>
  );
};

export const DolComprehensiveExperienceDetail = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(
            where: { name: "SmartLearning_ComprehensiveExperienceDetail" }
          ) {
            content
          }
        }
      }
    `);
    return (
      <DolComprehensiveExperienceDetailBase
        data={data.gcms.jsonContanier.content}
      />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
