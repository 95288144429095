import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { flexGap } from 'style/css-style';
import { BlendedSmartLearningTable } from './components/BlendedSmartLearningTable';
import { fromScreen } from 'utils/media-query/responsive.util';
import { IconsMessageIcon } from 'components/Icon/SVGIcons';
import { useInView } from 'react-cool-inview';
import { motion } from 'framer-motion';
import { LazyImage } from 'components/LazyImage';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${flexGap(48, 'column')}
`;

const HeaderTitle = styled.div`
  width: 100%;
  text-align: center;
  ${fromScreen(458)} {
    width: 445px;
  }
`;

const MainContent = styled.div`
  display: grid;
  gap: 39px;
`;

const MainTitle = styled.div`
  height: 120px;
  width: 100%;
  position: relative;
  .blue-arrow {
    position: absolute;
    left: 48%;
    bottom: -38px;
    img {
      height: 57px;
    }
  }
  ${fromScreen(458)} {
    .blue-arrow {
      left: 49.6%;
      bottom: -40px;
    }
  }
`;

const TitleBoxLeft = styled(motion.div)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorsV2.blue10};
  border: 1px solid rgba(125, 159, 232, 0.5);
  border-radius: 325px;
  ${fromScreen(458)} {
    width: 232px;
  }
  ${fromScreen(776)} {
    width: 375px;
  }
  ${fromScreen(1144)} {
    width: 560px;
  }
`;

const TitleBoxLeftOverride = styled(motion.div)`
  position: absolute;
  top: 1px;
  left: 2px;
  width: 54%;
  height: 98%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorsV2.blue10};
  border-radius: 325px;
  ${fromScreen(458)} {
    width: 230px;
  }
  ${fromScreen(776)} {
    width: 373px;
  }
  ${fromScreen(1144)} {
    width: 557px;
  }
`;

const TitleBoxRight = styled(motion.div)`
  position: absolute;
  right: 0;
  width: 55%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colorsV2.blue10};
  border: 1px solid rgba(125, 159, 232, 0.5);
  border-radius: 325px;
  ${fromScreen(458)} {
    width: 232px;
  }
  ${fromScreen(776)} {
    width: 375px;
  }
  ${fromScreen(1144)} {
    width: 560px;
  }
`;

const Outtro = styled.div`
  display: grid;
  grid-template-columns: 32px minmax(0, 1fr);
  justify-content: center;
  gap: 24px;
  svg {
    width: 32px;
    height: 34px;
  }
  ${fromScreen(776)} {
    grid-template-columns: 32px 600px;
  }
`;

const leftVariants = {
  active: {
    left: '0px',
    transition: { duration: 1 }
  },
  inactive: {
    left: '-90px'
  }
};

const leftOverrideVariants = {
  active: {
    left: '2px',
    transition: { duration: 1 }
  },
  inactive: {
    left: '-90px'
  }
};

const rightVariants = {
  active: {
    right: '0px',
    transition: { duration: 1 }
  },
  inactive: {
    right: '-90px'
  }
};

const blueArrowVariants = {
  active: {
    opacity: 1,
    transition: { duration: 1.5 }
  },
  inactive: {
    opacity: 0
  }
};
const DolComprehensiveExperienceBase = ({ data }) => {
  const { groups } = data;
  const [isScrolled, setIsScrolled] = React.useState(
    typeof window !== 'undefined' && window?.innerWidth >= 776 ? false : true
  );
  const { observe } = useInView({
    onEnter: () => {
      setIsScrolled(true);
    }
  });
  return (
    <Container>
      <HeaderTitle>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          Trải nghiệm học tập toàn diện ở DOL
        </Typography>
      </HeaderTitle>
      <MainContent ref={observe}>
        <MainTitle>
          <TitleBoxLeft
            initial={'inactive'}
            variants={leftVariants}
            animate={isScrolled ? 'active' : 'inactive'}
          >
            <Typography variant="semi-bold/24-32" color={colorsV2.blue100} v3>
              Học trên lớp
            </Typography>
          </TitleBoxLeft>
          <TitleBoxRight
            initial={'inactive'}
            variants={rightVariants}
            animate={isScrolled ? 'active' : 'inactive'}
          >
            <Typography variant="semi-bold/24-32" color={colorsV2.blue100} v3>
              Học ở nhà
            </Typography>
          </TitleBoxRight>
          <TitleBoxLeftOverride
            initial={'inactive'}
            variants={leftOverrideVariants}
            animate={isScrolled ? 'active' : 'inactive'}
          >
            <Typography variant="semi-bold/24-32" color={colorsV2.blue100} v3>
              Học trên lớp
            </Typography>
          </TitleBoxLeftOverride>
          <LazyImage
            initial={'inactive'}
            variants={blueArrowVariants}
            animate={isScrolled ? 'active' : 'inactive'}
            className="blue-arrow"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/7b5b497d-c47f-47b9-b195-6177f7ca75e1.png"
            alt="blue-arrow"
          />
        </MainTitle>
        <BlendedSmartLearningTable groups={groups} isScrolled={isScrolled} />
        <Outtro>
          <IconsMessageIcon />
          <Typography variant="regular/16-28" v3 color={colorsV2.neutral140}>
            Học viên tiếp thu kiến thức chuyên sâu trên lớp và củng cố vững chắc
            ở nhà, tạo nên sự tiến bộ nhanh chóng và đồng đều.
          </Typography>
        </Outtro>
      </MainContent>
    </Container>
  );
};

export const DolComprehensiveExperience = withHomePageSection(
  () => {
    const data = useStaticQuery(graphql`
      {
        gcms {
          jsonContanier(
            where: { name: "SmartLearning_ComprehensiveExperience" }
          ) {
            content
          }
        }
      }
    `);
    return (
      <DolComprehensiveExperienceBase data={data.gcms.jsonContanier.content} />
    );
  },
  {
    style: {
      backgroundColor: colorsV2.white100
    }
  },
  {}
);
