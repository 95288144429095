/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { SymbolsCheckMarkBig } from 'components/Icon/SVGIcons';
import cl from 'classnames';
import { VideoInfo } from './VideoInfo';
import { fromScreen } from 'utils/media-query/responsive.util';

const ImageBox = styled.div`
  z-index: 10;
  position: relative;
  .group-item-line-right-to-left {
    display: none;
    width: 565px;
    position: absolute;
    right: 48%;
    z-index: 1;
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .group-item-line-left-to-right {
    display: none;
    width: 565px;
    position: absolute;
    left: 51%;
    z-index: 1;
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .red-dot {
    display: none;
    position: absolute;
    width: 16px;
    height: 16px;
    z-index: 10;
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .top {
    top: -8px;
    left: 50%;
  }
  .bottom {
    bottom: -8px;
    left: 50%;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 1fr);
  gap: 32px;
  margin-bottom: 64px;
  .thumbnail-image {
    img {
      border-radius: 8px;
    }
  }
  ${fromScreen(776)} {
    grid-template-columns: minmax(0px, 180px) minmax(0px, 500px);
    align-items: start;
    justify-content: space-between;
    gap: 0px;
    &.reverse-columns {
      grid-template-columns: minmax(0px, 500px) minmax(0px, 180px);
      ${ImageBox} {
        order: 2;
      }
    }
  }
  ${fromScreen(1144)} {
    margin-bottom: 100px;
    grid-template-columns: minmax(0px, 520px) minmax(0px, 480px);
    align-items: center;
    &.reverse-columns {
      grid-template-columns: minmax(0px, 480px) minmax(0px, 520px);
    }
  }
`;
const Content = styled.div`
  display: grid;
  gap: 24px;
`;
const HeaderContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 24px) minmax(0, 1fr);
  gap: 12px;
  align-items: center;
  height: fit-content;
`;
const List = styled.div`
  display: grid;
  gap: 8px;
`;
const Item = styled.div`
  display: grid;
  grid-template-columns: 20px minmax(0, max-content);
  align-items: center;
  gap: 14px;
`;

export const TrackingCardItem = ({
  headerContent = '',
  emojiImage = '',
  emojiImageAlt = '',
  title = '',
  descriptions = [],
  thumbnailImage = '',
  thumbnailImageAlt = '',
  video = null,
  isReverse = false,
  isLast = false,
  isOneDotAtLast = false
}) => {
  return (
    <Container className={cl({ 'reverse-columns': isReverse })}>
      <ImageBox>
        <LazyImage
          className="thumbnail-image"
          src={thumbnailImage}
          alt={thumbnailImageAlt}
        />
        {video && <VideoInfo text={video?.content} link={video?.link} />}
        {isReverse && !isLast && (
          <LazyImage
            className="group-item-line-right-to-left"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/23fe4d1b-5a6e-4414-8dee-3a6840ff4f7e.png"
            alt="group-item-line-right-to-left"
          />
        )}
        {!isReverse && (
          <LazyImage
            className="group-item-line-left-to-right"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/caf6317e-98bd-4321-9cf0-f9b9fa534828.png"
            alt="group-item-line-left-to-right"
          />
        )}
        <LazyImage
          className="red-dot top"
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/6225d684-224e-4459-9abb-d89949dd79e1.png"
          alt="red-dot"
        />

        {!isOneDotAtLast && (
          <LazyImage
            className="red-dot bottom"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/6225d684-224e-4459-9abb-d89949dd79e1.png"
            alt="red-dot"
          />
        )}
      </ImageBox>
      <Content>
        <HeaderContent>
          <LazyImage src={emojiImage} alt={emojiImageAlt} />
          <Typography variant="semi-bold/18-24" color={colorsV2.primary100}>
            {headerContent}
          </Typography>
        </HeaderContent>
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          {title}
        </Typography>
        <List>
          {descriptions?.map((eachDescription, idx) => (
            <Item key={idx}>
              <SymbolsCheckMarkBig color={colorsV2.primary100} />
              <Typography variant="medium/16-24" color={colorsV2.neutral180}>
                {eachDescription}
              </Typography>
            </Item>
          ))}
        </List>
      </Content>
    </Container>
  );
};
