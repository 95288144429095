import * as React from 'react';
import styled from 'styled-components/macro';

/* eslint-disable react/prop-types */
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageFullScreen } from 'components/withHomePageFullScreen';
import { colorsV2 } from 'style/colors-v2';
import { TagLabel } from 'shared/TagLabel/TagLabel';
import { flexGap } from 'style/css-style';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  gap: 73px;
  padding-top: 63px;
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .iframe-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const HeaderTitle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${flexGap(24, 'column')};
  margin: auto;
  text-align: center;
  .tag-header {
    width: fit-content;
    border-radius: 38px;
  }
  .info {
    border-radius: 12px;
  }
  .header-des {
    width: 100%;
    padding: 0 16px;
  }
  ${fromScreen(458)} {
    .header-des {
      padding: 0;
      width: 426px;
    }
  }
  ${fromScreen(776)} {
    .header-des {
      width: 475px;
    }
  }
`;

const Header = () => {
  return (
    <Container>
      <HeaderTitle>
        <TagLabel
          className="tag-header"
          text="Trải nghiệm học tập khác biệt"
          color="primary"
        />
        <Typography
          variant="bold/24-32"
          tabletVariant="bold/32-40"
          desktopVariant="bold/40-48"
          color={colorsV2.black100}
          v3
        >
          DOL blended-smart learning
        </Typography>
        <Typography
          className="header-des"
          variant="regular/16-24"
          color={colorsV2.neutral120}
        >
          Tại DOL, việc học Tiếng Anh không chỉ dừng ở việc lên lớp, mà đó còn
          là một hành trình trải nghiệm xuyên suốt từ trên lớp đến về nhà, từ
          thầy cô đến bản thân và môi trường học tập.
        </Typography>
      </HeaderTitle>
      <div className="iframe-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/G6TvFZDua48?si=_BP-IyVR_5v_V20i&autoplay=1&loop=1&playlist=G6TvFZDua48&rel=0&modestbranding=1&mute=1&controls=0"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Container>
  );
};

export const SmartLearningHeader = withHomePageFullScreen(
  () => {
    return <Header />;
  },
  {
    style: {
      backgroundColor: colorsV2.paper
    }
  },
  {
    style: {
      maxWidth: '1680px',
      padding: '0px'
    }
  }
);
