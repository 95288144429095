import * as React from 'react';
import MainLayout from 'components/Layout/MainLayout';
import HeaderSchedulePage from 'shared/Header/HeaderSchedulePage';
import MySEO from 'shared/MySEO';
import { fromScreen } from 'utils/media-query/responsive.util';
import styled from 'styled-components/macro';
import { SmartLearningHeader } from 'shared/SmartLearning/SmartLearningHeader';
import { AboutDol } from 'shared/SmartLearning/AboutDol';
import { DolComprehensiveExperience } from 'shared/SmartLearning/DolComprehensiveExperience';
import { DolComprehensiveExperienceDetail } from 'shared/SmartLearning/DolComprehensiveExperienceDetail';
import HallOfFame from 'shared/SmartLearning/HallOfFame';
import { StudentExperience } from 'shared/SmartLearning/StudentExperience';

const SmartLearning = ({ location }) => {
  return (
    <MainLayout
      noHeader
      hideSideCTA
      renderCustomHeader={() => <HeaderSchedulePage />}
      showFooterRegister
    >
      <MySEO
        title="DOL blended-smart learning"
        slug={location.pathname}
        seoTitle="DOL blended-smart learning"
        seoDesc="Tại DOL, việc học Tiếng Anh không chỉ dừng ở việc lên lớp, mà đó còn là một hành trình trải nghiệm xuyên suốt từ trên lớp đến về nhà, từ thầy cô đến bản thân và môi trường học tập."
        description="Tại DOL, việc học Tiếng Anh không chỉ dừng ở việc lên lớp, mà đó còn là một hành trình trải nghiệm xuyên suốt từ trên lớp đến về nhà, từ thầy cô đến bản thân và môi trường học tập."
        featureImg="N23sKZnZQzeQsYT8sZWS"
      />
      <SmartLearningHeader />
      <Space>
        <AboutDol
          content={`“Đối với DOL, Tiếng Anh chỉ là một công cụ. DOL muốn giúp cho học viên giỏi Tiếng Anh nhanh hơn, dễ dàng hơn. Từ đó học viên còn thời gian dùng Tiếng Anh chinh phục các mục tiêu khác.”`}
        />
        <DolComprehensiveExperience />
      </Space>
      <DolComprehensiveExperienceDetail />
      <SpaceRelative>
        <AboutDol
          content={`“DOL không chỉ mang đến lớp học chất lượng mà còn tạo nên trải nghiệm học tập toàn diện, giúp học viên vừa yêu việc học và học rất nhanh tiến bộ.”`}
          hasMask={true}
        />
      </SpaceRelative>
      <Space className="hof">
        <HallOfFame />
      </Space>
      <Space>
        <StudentExperience />
      </Space>
    </MainLayout>
  );
};

const Space = styled.div`
  > section {
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
  &.hof {
    display: none;
    ${fromScreen(776)} {
      display: block;
    }
  }
`;

const SpaceRelative = styled.div`
  > section {
    position: relative;
    padding: 64px 0 !important;
    ${fromScreen(776)} {
      padding: 100px 0 !important;
    }
  }
`;

export default React.memo(SmartLearning);
