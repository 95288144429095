/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { TrackingCardItem } from './TrackingCardItem';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  display: grid;
  gap: 48px;
  margin-top: 100px;
  z-index: 10;
  ${fromScreen(1144)} {
    gap: 64px;
  }
`;

const Content = styled.div`
  position: relative;
  display: grid;
  gap: 12px;
  width: fit-content;
  text-align: center;
  border: 1px solid ${colorsV2.primary100};
  border-radius: 16px;
  box-shadow: 1px 2px 0px 0px ${colorsV2.primary100};
  padding: 16px 32px;
  margin: auto;
  .content-image {
    img {
      width: 140px;
      height: 140px;
    }
  }
  .group-line-left {
    display: none;
    width: 179px;
    position: absolute;
    top: 53%;
    right: 197px;
    z-index: 1;
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .group-line-right {
    display: none;
    width: 182px;
    position: absolute;
    bottom: 45%;
    left: 210px;
    z-index: 1;
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .red-dot {
    position: absolute;
    display: none;
    width: 16px;
    height: 16px;
    z-index: 10;
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .left {
    top: 50%;
    left: -9px;
  }
  .right {
    top: 50%;
    right: -9px;
  }
`;

const TrackingList = styled.div`
  display: grid;
  gap: 64px;
  ${fromScreen(1144)} {
    gap: 100px;
  }
`;

export const TrackingCard = ({
  title = '',
  list = [],
  isTwoDot = false,
  image = '',
  imageAlt = ''
}) => {
  return (
    <Main>
      <Content>
        <LazyImage className="content-image" src={image} alt={imageAlt} />
        <Typography variant="semi-bold/16-24" color={colorsV2.primary100}>
          {title}
        </Typography>

        <LazyImage
          className="group-line-left"
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/4cf7ec62-ddc8-4732-bdde-8717034c4501.png"
          alt="group-line-left"
        />
        {isTwoDot && (
          <LazyImage
            className="group-line-right"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/7670f380-e1b3-4a73-a068-37293a9a5b73.png"
            alt="group-line-right"
          />
        )}
        <LazyImage
          className="red-dot left"
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/6225d684-224e-4459-9abb-d89949dd79e1.png"
          alt="red-dot"
        />
        {isTwoDot && (
          <LazyImage
            className="red-dot right"
            src="https://gqefcpylonobj.vcdn.cloud/directus-upload/6225d684-224e-4459-9abb-d89949dd79e1.png"
            alt="red-dot"
          />
        )}
      </Content>
      <TrackingList>
        {list?.map((item, idx) => (
          <TrackingCardItem
            key={idx}
            headerContent={item?.headerContent}
            emojiImage={item?.emojiImage}
            emojiImageAlt={item?.emojiImageAlt}
            title={item?.title}
            thumbnailImage={item?.thumbnailImage}
            thumbnailImageAlt={item?.thumbnailImageAlt}
            descriptions={item?.descriptions}
            video={item?.video}
            isReverse={idx === 1 || idx === 3}
            isLast={idx === list?.length - 1}
            isOneDotAtLast={idx === list?.length - 1 && title === 'Ở NHÀ'}
          />
        ))}
      </TrackingList>
    </Main>
  );
};
