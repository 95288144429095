import * as React from 'react';

function IconPlay(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M11.1999 14.6002L14.3133 12.2668C14.4933 12.1335 14.4933 11.8668 14.3133 11.7335L11.1999 9.40016C10.9799 9.2335 10.6666 9.3935 10.6666 9.66683V14.3335C10.6666 14.6068 10.9799 14.7668 11.1999 14.6002ZM11.9999 5.3335C8.31992 5.3335 5.33325 8.32016 5.33325 12.0002C5.33325 15.6802 8.31992 18.6668 11.9999 18.6668C15.6799 18.6668 18.6666 15.6802 18.6666 12.0002C18.6666 8.32016 15.6799 5.3335 11.9999 5.3335ZM11.9999 17.3335C9.05992 17.3335 6.66659 14.9402 6.66659 12.0002C6.66659 9.06016 9.05992 6.66683 11.9999 6.66683C14.9399 6.66683 17.3333 9.06016 17.3333 12.0002C17.3333 14.9402 14.9399 17.3335 11.9999 17.3335Z"
        fill="#5A6877"
      />
    </svg>
  );
}

const IconsSmartLearningPlay = React.memo(IconPlay);
export default IconsSmartLearningPlay;
