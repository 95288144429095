import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import { colorsV2 } from 'style/colors-v2';
import { Typography } from 'components/DesignSystemV2/Typography';
import { withHomePageSliderSection } from 'components/withHomePageSliderSection';
import { IconsRankingCrowns } from 'components/Icon/SVGIcons';
import { ListHallOfFame } from './components/ListHallOfFame';
import ButtonText from 'components/ButtonText';
import { fromScreen } from 'utils/media-query/responsive.util';
import cl from 'classnames';

const Header = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 472px) minmax(0px, max-content);
  align-items: end;
  justify-content: space-between;
  margin-bottom: 60px;
`;
const LeftSide = styled.div`
  display: grid;
  gap: 12px;
`;
const RightSide = styled.div``;

const Info = styled.div`
  display: grid;
  gap: 8px;
`;

const Footer = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 504px) max-content;
  justify-content: center;
  align-items: center;
  gap: 32px;
`;
const Progress = styled.div`
  height: 3px;
  background: ${colorsV2.yellow20};
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 8px;
  border-radius: 8px;
`;

const ProgressItem = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  border-radius: 10px;
  transition: all 500ms;
  &.actived {
    background: ${colorsV2.yellow60};
  }
`;

const fetchHallOfFame = async ({ page, limit }) => {
  const res = await axios.get(
    `${process.env.NODE_ENDPOINT}/hallOfFameList?_page=${page}&_limit=${limit}&_sort=overall,date&_order=desc,desc&program=IELTS`
  );
  return res;
};

const reducer = (prevState, nextState) => ({ ...prevState, ...nextState });

const HallOfFame = () => {
  const [centerImageIndex, setCenterImageIndex] = React.useState(null);

  const data = useStaticQuery(graphql`
    query {
      hofConnection: allGoogleSpreadsheetDolLandingPageResultIelts(
        filter: { bangVangListed: { eq: "TRUE" } }
        sort: { order: DESC, fields: overall }
      ) {
        distinct(field: bangVangAvatar)
      }
    }
  `);

  const { hofConnection } = data;

  const [state, setState] = useReducer(reducer, {
    total: hofConnection.distinct.length,
    loaded: 0,
    hofList: [],
    loading: false,
    size: 6
  });

  const { loaded, hofList, size, page = 1 } = state;

  function isImageHorizontallyCentered(pageWidth, imageWidth, imageX) {
    // Calculate the expected X coordinate for the image to be centered
    const expectedX = (pageWidth - imageWidth) / 2;

    // Define a margin of error, if necessary (e.g., for rounding)
    const marginOfError = 10; // Adjust this value based on your requirements

    // Check if the actual X coordinate is within the expected range
    return Math.abs(imageX - expectedX) <= marginOfError;
  }

  const checkImagePosition = () => {
    const pageWidth = window.innerWidth;

    outer: for (let index = 0; index < 6; index++) {
      const images = document.querySelectorAll(`[id="image-${index}"`);
      for (const img of images) {
        const rect = img.getBoundingClientRect();
        const imageWidth = rect.width;
        const imageX = rect.left + window.scrollX;

        const test = isImageHorizontallyCentered(pageWidth, imageWidth, imageX);
        if (test) {
          setCenterImageIndex(index);
          break outer;
        }
      }
    }
  };

  const loadHallOfFame = useCallback(() => {
    setState({ loading: true });
    fetchHallOfFame({ page, limit: size })
      .then(res => {
        const list = res.data;
        setState({
          hofList: hofList.concat(list),
          loaded: loaded + list.length,
          page: page + 1,
          loading: false
        });
      })
      .catch(() => {
        setState({ loading: false });
      });
  }, [hofList, loaded, size, page]);

  useEffect(() => {
    loadHallOfFame();
    const intervalId = setInterval(checkImagePosition, 200); // Check every 200ms
    return () => clearInterval(intervalId);
    // Reason: Fetch once on mount
    /*eslint-disable-next-line*/
  }, []);

  return (
    <HOFContainer>
      <Header>
        <LeftSide>
          <IconsRankingCrowns
            width={40}
            height={40}
            color={colorsV2.yellow100}
          />
          <Typography
            variant="bold/24-32"
            tabletVariant="bold/32-40"
            desktopVariant="bold/40-48"
            color={colorsV2.black100}
            v3
          >
            Bảng vàng thành tích của học viên DOL
          </Typography>
        </LeftSide>
        <RightSide>
          <Info>
            <Typography variant="medium/24-32" color={colorsV2.neutral200}>
              2.000+ học viên
            </Typography>
            <Typography variant="regular/16-24" color={colorsV2.neutral180}>
              đạt IELTS cao từ 7.0
            </Typography>
          </Info>
        </RightSide>
      </Header>
      <ListHallOfFame hofList={hofList} />
      <Footer>
        <Progress>
          {hofList?.map((_, idx) => (
            <ProgressItem
              key={idx}
              className={cl({ actived: idx === centerImageIndex })}
            />
          ))}
        </Progress>
        <Link to="/hall-of-fame">
          <ButtonText content="Xem tất cả" type="primary" />
        </Link>
      </Footer>
    </HOFContainer>
  );
};

export const HOFContainer = styled.div`
  ${Header} {
    padding: 0 16px;
    margin: 0 auto 24px;
  }
  ${fromScreen(458)} {
    ${Header} {
      max-width: 457px;
    }
  }

  ${fromScreen(776)} {
    ${Header} {
      max-width: 712px;
      padding: 0;
      margin: 0 auto 40px;
    }
  }
  ${fromScreen(1144)} {
    ${Header} {
      max-width: 100%;
    }
  }
`;

HallOfFame.propTypes = {
  hofList: PropTypes.arrayOf(PropTypes.shape({})),
  bellowHeader: PropTypes.node,
  paging: PropTypes.boolean,
  order: PropTypes.string
};

HallOfFame.defaultProps = {
  hofList: [],
  bellowHeader: null,
  paging: true
};

export default withHomePageSliderSection(
  HallOfFame,
  {
    style: {
      background: colorsV2.paper
    }
  },
  {}
);
