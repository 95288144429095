import * as React from 'react';

function IconButtonPlay(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 32 33" fill="none" {...props}>
      <path
        d="M24.585 15.224L9.347 4.624a.98.98 0 00-1.042-.053 1.067 1.067 0 00-.537.935v21.2c0 .392.206.751.536.936a.984.984 0 001.043-.053l15.238-10.6c.283-.198.452-.529.452-.883s-.17-.685-.452-.882z"
        fill="currentColor"
      />
    </svg>
  );
}

const IconsJuniorButtonPlay = React.memo(IconButtonPlay);
export default IconsJuniorButtonPlay;
