/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { BlendedSmartLearningItem } from './BlendedSmartLearningItem';
import { fromScreen } from 'utils/media-query/responsive.util';
import { motion } from 'framer-motion';

const Container = styled(motion.div)`
  border: 1px solid ${colorsV2.neutral20};
  border-radius: 16px;
`;
const Header = styled.div`
  display: grid;
  justify-content: center;
  text-align: center;
  gap: 8px;
  padding: 16px 24px;
`;
const MainContent = styled.div``;
const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${colorsV2.neutral20};
`;

const ListContent = styled.div`
  display: grid;
  grid-template-columns: minmax(0px, 1fr);
  ${fromScreen(776)} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

const variants = {
  active: {
    opacity: 1,
    transition: { duration: 2.5 }
  },
  inactive: {
    opacity: 0
  }
};
export const BlendedSmartLearningTable = ({ groups, isScrolled = false }) => {
  return (
    <Container
      initial={'inactive'}
      variants={variants}
      animate={isScrolled ? 'active' : 'inactive'}
    >
      <Header>
        <Typography variant="semi-bold/16-20" color={colorsV2.neutral120}>
          Blended Smart Learning
        </Typography>
        <Typography variant="semi-bold/16-24" color={colorsV2.black100}>
          Trên lớp và tại nhà với sự hỗ trợ toàn diện
        </Typography>
      </Header>
      <Divider />
      <MainContent>
        <ListContent>
          {groups?.map((eachGroup, idx) => (
            <BlendedSmartLearningItem
              key={idx}
              title={eachGroup?.title}
              image={eachGroup?.image}
              imageAlt={eachGroup?.imageAlt}
              list={eachGroup?.list}
            />
          ))}
        </ListContent>
      </MainContent>
    </Container>
  );
};
