/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import Marquee from 'react-fast-marquee';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  position: relative;
  margin-bottom: 72px;
`;
const ListItem = styled.div``;
const Item = styled.div`
  width: 356px;
  height: fit-content;
  display: grid;
  gap: 16px;
  padding: 0 6px;
  &:hover {
    .certificate {
      bottom: 0;
    }
  }
`;
const ImageBox = styled.div`
  position: relative;
  padding-top: 44px;
  overflow: hidden;
  .image {
    img {
      width: 356px;
      height: 264px;
      border-radius: 20px;
      object-fit: cover;
    }
  }
  .certificate {
    position: absolute;
    height: 305px;
    bottom: -100%;
    padding: 0 12px;
    z-index: 10;
    transition: all 400ms ease-in-out;
    img {
      border: 1px solid ${colorsV2.neutral60};
      border-radius: 12px;
    }
  }
`;
const LeaderBoardCard = styled.div`
  position: absolute;
  bottom: 0;
  padding: 12px;
`;
const ScoreWrapper = styled.div`
  text-align: center;
  border-radius: 16px;
  padding: 16px;
  backdrop-filter: blur(32px);
  background: rgba(255, 255, 255, 0.8);
`;

const ContentBox = styled.div`
  display: grid;
  gap: 8px;
  padding: 0 16px;
  .info-subtitle {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;

const MaskLeft = styled.div`
  position: absolute;
  top: 0;
  display: none;
  height: 100%;
  width: 160px;
  left: -2px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(776)} {
    display: block;
  }
`;

const MaskRight = styled.div`
  position: absolute;
  top: 0;
  display: none;
  height: 100%;
  width: 160px;
  right: -2px;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  transform: rotate(180deg);
  z-index: 1;
  pointer-events: none;
  ${fromScreen(776)} {
    display: block;
  }
`;

export const ListHallOfFame = ({ hofList }) => {
  return (
    <Container>
      <ListItem>
        <Marquee speed={100} pauseOnHover={true}>
          {hofList?.map((eachHof, idx) => {
            const {
              bangVangAvatar,
              bangVangCertificate,
              bangVangDisplayName,
              // bangVangListed,
              bangVangOccupation,
              overall
            } = eachHof || {};
            return (
              <Item key={idx} id={`image-${idx}`}>
                <ImageBox>
                  <LazyImage
                    className="image"
                    imgProps={{
                      alt: 'bang-vang-avatar'
                    }}
                    handle={bangVangAvatar}
                  />
                  <LazyImage
                    className="certificate"
                    imgProps={{
                      alt: 'certificate'
                    }}
                    handle={bangVangCertificate}
                  />
                  <LeaderBoardCard>
                    <ScoreWrapper>
                      <Typography
                        variant="bold/40-48"
                        color={colorsV2.primary100}
                      >
                        {overall}
                      </Typography>
                      <Typography
                        variant="regular/16-24"
                        color={colorsV2.neutral120}
                      >
                        IELTS
                      </Typography>
                    </ScoreWrapper>
                  </LeaderBoardCard>
                </ImageBox>
                <ContentBox>
                  <Typography
                    variant="semi-bold/24-32"
                    color={colorsV2.neutral200}
                  >
                    {bangVangDisplayName}
                  </Typography>
                  <Typography
                    variant="regular/16-24"
                    color={colorsV2.neutral120}
                    className="info-subtitle"
                  >
                    {bangVangOccupation}
                  </Typography>
                </ContentBox>
              </Item>
            );
          })}
        </Marquee>
      </ListItem>
      <MaskLeft />
      <MaskRight />
    </Container>
  );
};
