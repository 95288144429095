import * as React from 'react';

/* eslint-disable react/prop-types */
import { withHomePageSection } from 'components/withHomePageSection';
import { colorsV2 } from 'style/colors-v2';
import { Info } from './components/Info';

const AboutDolBase = ({ content = '', hasMask = false }) => {
  return <Info content={content} hasMask={hasMask} />;
};

export const AboutDol = withHomePageSection(
  data => {
    const { content, hasMask } = data || {};
    return <AboutDolBase content={content} hasMask={hasMask} />;
  },
  {
    style: {
      backgroundColor: colorsV2.black100
    }
  },
  {}
);
