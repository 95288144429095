/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import IconsSmartLearningPlay from '../../icons/IconPlay';
import { flexGap } from 'style/css-style';
import { fromScreen } from 'utils/media-query/responsive.util';

const Main = styled.div`
  width: 100%;
  height: 201px;
  background: linear-gradient(
    180deg,
    rgba(49, 58, 68, 0) 0%,
    ${colorsV2.neutral180} 100%
  );
  position: absolute;
  bottom: 0;
  display: none;
  grid-template-columns: minmax(0px, max-content) max-content;
  gap: 16px;
  align-items: end;
  padding: 16px;
  border-radius: 8px;
  ${fromScreen(1144)} {
    display: grid;
  }
`;

const ButtonBox = styled.a`
  height: 28px;
  display: flex;
  align-items: center;
  ${flexGap(4)};
  background: ${colorsV2.neutral10};
  padding: 2px 10px;
  border-radius: 10px;
  margin-bottom: 6px;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
  }
`;
export const VideoInfo = ({ text, link }) => {
  return (
    <Main>
      <Typography variant="medium/14-20" color={colorsV2.white100} v3>
        {text}
      </Typography>
      <ButtonBox href={link} target="_blank" rel="noreferrer noopener">
        <Typography variant="medium/14-20" color={colorsV2.neutral180} v3>
          Xem video
        </Typography>
        <IconsSmartLearningPlay />
      </ButtonBox>
    </Main>
  );
};
