/* eslint-disable react/prop-types */
import * as React from 'react';
import styled from 'styled-components/macro';
import { Typography } from 'components/DesignSystemV2/Typography';
import { colorsV2 } from 'style/colors-v2';
import { LazyImage } from 'components/LazyImage';
import { flexGap } from 'style/css-style';
import { fromScreen } from 'utils/media-query/responsive.util';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${flexGap(48, 'column')}
  .mask-left {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    img {
      height: 100%;
    }
    ${fromScreen(1144)} {
      display: block;
    }
  }
  .mask-right {
    display: none;
    transform: rotate(180deg);
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    img {
      height: 100%;
    }
    ${fromScreen(1144)} {
      display: block;
    }
  }
`;

const Text = styled.div`
  width: 100%;
  text-align: center;
  ${fromScreen(1144)} {
    width: 726px;
  }
`;

export const Info = ({ content = '', hasMask = false }) => {
  return (
    <Container>
      <Text>
        <Typography
          variant="bold/20-28"
          tabletVariant="semi-bold/28-36"
          color={colorsV2.white100}
        >
          {content}
        </Typography>
      </Text>
      <img
        alt="teacher-ielts-sat-junior-logo"
        width={122}
        height={40}
        src="https://gqefcpylonobj.vcdn.cloud/directus-upload/1f013aa8-2a36-4102-a758-e98d4ba23b25.png"
        loading="eager"
      />
      {hasMask && (
        <LazyImage
          className="mask-left"
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/1de18488-de43-4ca8-a06c-ca050632d5d9.png"
          alt="mask-left"
        />
      )}
      {hasMask && (
        <LazyImage
          className="mask-right"
          src="https://gqefcpylonobj.vcdn.cloud/directus-upload/1de18488-de43-4ca8-a06c-ca050632d5d9.png"
          alt="mask-right"
        />
      )}
    </Container>
  );
};
